@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-display: block;
  src: url('./typography/IcoMoon/icomoon.eot') format('embedded-opentype'),
    url('./typography/IcoMoon/icomoon.ttf') format('truetype'),
    url('./typography/IcoMoon/icomoon.svg') format('svg'),
    url('./typography/IcoMoon/icomoon.woff') format('woff');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_forward_ios:before {
  content: '\e906';
}
.icon-arrow_back_ios:before {
  content: '\e907';
}
.icon-warning:before {
  content: '\e905';
}
.icon-th-menu:before {
  content: '\e904';
}
.icon-file-text:before {
  content: '\e901';
}
.icon-sign-out:before {
  content: '\e903';
}
.icon-folder-open1:before {
  content: '\e902';
}
.icon-calculator:before {
  content: '\e900';
}
